<template>
  <div>
    <a-modal v-model="visibleShow" title="Basic Modal" id="modsahjd" @ok="handleOk">
      <div v-for="(item, index) in printList" :key="index">
        <div :id="`div1${item.orderSn}`">
          <span style="font-size: 30px; font-weight: 900">Packing List</span>
          <br />
          <span>Platform Order ID: {{ item.platformOrderId }}</span>
          <br />
          <span>Platform Order Time: {{ item.platformOrderTime }}</span>
          <br />
          <span>Order Sn: {{ item.orderSn }}</span>
          <br />
          <span>Total SKU: {{ item.orderGoodses.length }}</span>
          <br />

          <span>
            Buyer Note:
            {{ item.buyerMemo }}
          </span>
          <br />
          <span style="margin-bottom: 10px">
            Seller Note:
            {{ item.sellerMemo }}
          </span>
        </div>
        <div :id="`div2${item.orderSn}`">
          <table
            v-if="item.orderGoodses"
            class="tableFa"
            cellspacing="0"
            cellpadding="1"
            width="100%"
            style="margin-top: 10px"
          >
            <thead>
              <tr>
                <td width="10%">
                  <div align="center">
                    <b>QTY</b>
                  </div>
                </td>
                <td width="40%">
                  <div align="center">
                    <b>Product Name</b>
                  </div>
                </td>
                <td width="25%">
                  <div align="center">
                    <b>Seller SKU</b>
                  </div>
                </td>
                <td width="25%">
                  <div align="center">
                    <b>Pre</b>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <template v-for="(otem, ondex) in item.orderGoodses">
                <tr style="font-size: 13px" :key="ondex">
                  <td align="center">
                    {{ otem.number }}
                  </td>
                  <td align="center">
                    {{ otem.sku?.skuName }}
                  </td>
                  <td align="center">
                    {{ otem.sku?.barcode }}
                  </td>
                  <td align="center">
                    {{ otem.skuType == 'PRESALE' ? 'Pre' : '' }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { getLodop } from '@/assets/LodopFuncs.js'
export default {
  data() {
    return { time: '', visibleShow: false }
  },
  props: {
    printList: [],
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(value) {
      // console.log(value)
      this.visibleShow = value
      if (value) {
        // console.log(this.printList)
        this.getDate()
        setTimeout(() => {
          this.printPickList()
        }, 100)
      }
    },
  },
  // mounted() {
  //   console.log(this.printList)
  //   console.log(this.visible)
  // },
  methods: {
    getDate() {
      const nowDate = new Date()
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minute: nowDate.getMinutes(),
        second: nowDate.getSeconds(),
      }
      const newmonth = date.month >= 10 ? date.month : '0' + date.month
      const day = date.date >= 10 ? date.date : '0' + date.date
      // console.log(date.year + '-' + newmonth + '-' + day)

      this.time =
        date.year +
        '/' +
        newmonth +
        '/' +
        day +
        ' ' +
        date.hours +
        ':' +
        date.minute +
        ':' +
        date.second
    },
    handleOk() {
      this.printPickList()
    },
    printPickList() {
      // console.log(getLodop)
      var LODOP = getLodop()
      this.printList.forEach((item, index) => {
        if (index > 0) {
          LODOP.NewPage()
        }
        LODOP.PRINT_INIT(new Date())
        LODOP.SET_PRINT_PAGESIZE(0, 1000, 1500, '')
        LODOP.ADD_PRINT_HTM(
          5,
          '5%',
          '90%',
          160,
          document.getElementById(`div1${item.orderSn}`).innerHTML
        )
        LODOP.SET_PRINT_STYLEA(0, 'ItemType', 1)
        LODOP.SET_PRINT_STYLEA(0, 'LinkedItem', 1)
        var strStyle =
          '<style> table,td,th {border-width: 1px;border-style: solid;border-collapse: collapse}</style>'
        LODOP.ADD_PRINT_TABLE(
          170,
          '5%',
          '90%',
          400,
          strStyle + document.getElementById(`div2${item.orderSn}`).innerHTML
        )

        LODOP.SET_PRINT_STYLEA(0, 'Vorient', 3)
        let printList =
          localStorage.getItem('printList') && JSON.parse(localStorage.getItem('printList'))
        if (printList) {
          printList.forEach((item) => {
            if (item.key == 1) {
              //1 拣货单
              LODOP.SET_PRINTER_INDEX(item.print)
            }
          })
        }
        // LODOP.PREVIEW()
        LODOP.PRINT()
        // emits("getList", {})
        this.$emit('cancel', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
}
.box {
  width: 100%;
  //   display: flex;
  //   justify-content: space-between;
  .minibox {
    width: 250px;
    text-align: left;
    display: inline-block;
  }
}
.tableFa {
  td {
    border: 1px solid gray;
    text-align: center;
    vertical-align: middle;
  }
}
.titleBox {
  div {
    width: 33%;
    // float: left;
  }
  .titleCenter {
    text-align: center;
  }
}
</style>
