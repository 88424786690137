<template>
  <a-select
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="4"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    v-model="selectedValue"
    :not-found-content="loading ? undefined : ''"
    :filter-option="filterOption"
    @focus="getList"
    @change="onChange"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="(item, index) in orderSubTypeList" :value="item.orderType" :key="index">
      {{ item.description }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'
import { mapState } from 'vuex'
export default {
  mounted() {
    this.setDefault()
  },
  props: {
    placeholder: {},
    disabled: {},
    defaultType: {},
    list: {},
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      orderSubTypeList: [],
      selectedValue: undefined,
    }
  },
  computed: {
    ...mapState(['orderSubTypeMapping']),
    _selfPlaceholder() {
      return this.placeholder || this.$t(`请选择订单子类型`)
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  methods: {
    async getList() {
      if (this.orderSubTypeList.length === 0) {
        this.loading = true
        let res = await http.post(api.getOrdetSubTypeList, {
          subType: true,
        })
        this.loading = false
        this.orderSubTypeList = res.result
      }
    },
    setDefault() {
      this.selectedValue = this.defaultType
    },
    onChange() {
      if (this.selectedValue != undefined) {
        this.$emit('change', this.selectedValue)
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  watch: {
    defaultType() {
      this.setDefault()
    },
    list: {
      handler(newVal) {
        if (newVal && Array.isArray(newVal)) {
          this.orderSubTypeList = newVal
        }
      },
      immediate: true,
    },
  },
}
</script>
