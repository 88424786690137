<template>
  <a-select
    style="width: 100%"
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="2"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    v-model="selectedValue"
    :filter-option="filterOption"
    @change="onChange"
  >
    <a-select-option v-for="(item, index) in option" :value="item.statusCode" :key="index">
      {{ item.statusValue }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'
export default {
  mounted() {
    this.getList()
  },
  props: {
    placeholder: {},
    disabled: {},
    code: {},
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedValue: undefined,
      option: [],
    }
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder || this.$t(`订单状态`)
    },
    _selfDisabled() {
      return this.disabled || false
    },
  },
  methods: {
    async getList() {
      let res = await http({
        url: api.getOrderStatusList,
        type: 'get',
        data: { type: 'OrderStatus' },
      })
      this.option = res.result
      if (this.code != undefined) {
        this.onChange(this.code)
      }
    },
    onChange(value) {
      this.selectedValue = value
      this.$emit('update:code', value)
      let subTypeList = this.option.find((item) => item.statusCode === value)?.subTypeList || []
      this.$emit('change', value, subTypeList)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  watch: {
    code(val) {
      this.selectedValue = val
    },
  },
}
</script>
