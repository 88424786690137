<template>
  <!-- 仓库搜索指定组件 别的都要被淘汰 -->
  <a-select
    v-if="mode === 'multiple'"
    allowClear
    mode="multiple"
    :placeholder="_selfPlaceholder"
    v-model="valueList"
    option-filter-prop="children"
    style="width: 100%"
    :filter-option="filterOption"
    :disabled="disabled"
    :not-found-content="loading ? undefined : null"
    @focus="focusHandle"
    @change="chooseWarehouse"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option
      v-for="item in warehouseList"
      :key="item.code"
      :value="item.code"
      :option="item"
    >
      {{ item.name }}
    </a-select-option>
  </a-select>
  <a-select
    v-else
    show-search
    allowClear
    :placeholder="_selfPlaceholder"
    v-model="value"
    option-filter-prop="children"
    style="width: 100%"
    :filter-option="filterOption"
    :disabled="disabled"
    :not-found-content="loading ? undefined : null"
    @focus="focusHandle"
    @change="handleChange"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="item in warehouseList" :key="item.code" :value="item.code">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import api from '@service/api'
import http from '@service/axios'

export default {
  name: 'SearchWarehouse',
  data() {
    return {
      value: undefined,
      valueList: [],
      warehouseList: [],
      loading: false,
    }
  },
  /**
   * shopList: { shopCode: "", platform: "" }
   */
  // props: ['code', 'name', 'placeholder', 'disabled', 'mode', 'shopList', 'oneSelect'],
  props: {
    code: {},
    name: {},
    placeholder: {},
    disabled: {},
    mode: {},
    shopList: {},
    oneSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _selfPlaceholder() {
      return this.placeholder || this.$t('请选择仓库')
    },
  },
  methods: {
    focusHandle() {
      if (this.warehouseList.length) return
      this.searchWarehouseList()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    searchWarehouseList() {
      let shopList = this.shopList?.length ? this.shopList : []
      this.loading = true
      const data = {
        shopUniqueList: shopList,
        merchantCode: localStorage.getItem('merchantCode'),
      }
      http({
        url: api.getWarehouseListByShop,
        type: 'post',
        data,
        success: (res) => {
          this.loading = false
          let warehouseList = res.result
          this.warehouseList = warehouseList
          // if (warehouseList.length === 0 && shopList.length === 1) {
          //   this.$confirm({
          //     title: this.$t('仓库为空'),
          //     content: this.$t('该店铺下仓库列表为空，请前往店铺管理配置'),
          //     okText: this.$t('去配置'),
          //     onOk: () => {
          //       this.$router.push(
          //         `/shop_web/deployInfo?platform=${shopList[0]?.platform}&shopCode=${shopList[0]?.shopCode}`
          //       )
          //     },
          //   })
          //   return
          // }
          if (this.code) {
            if (this.mode === 'multiple') {
              this.valueList = this.code
            } else {
              this.value = this.code
            }
          }
          if (this.oneSelect && warehouseList.length === 1) {
            this.handleChange(warehouseList[0].code)
          }
        },
      })
    },
    handleChange(val) {
      const data = this.warehouseList.find((item) => item.code === val)
      this.$emit('update:code', val)
      if (data) {
        this.$emit('update:name', data?.warehouseName)
        this.$emit('selected', {
          warehouseCode: data.code,
          wmsWarehouseCode: data.code,
          wmsAppCode: data.wmsAppCode,
          warehouseType: data.wmsAppCode,
          warehouseChannel: data.wmsAppCode,
          uniqueCode: data.uniqueCode,
        })
      } else {
        this.$emit('selected', {})
      }
    },
    chooseWarehouse(codes, options) {
      this.$emit('update:code', codes)
      let list = options.map((item) => {
        let data = item.data.attrs?.option
        return {
          warehouseCode: data.code,
          wmsWarehouseCode: data.code,
          wmsAppCode: data.wmsAppCode,
          warehouseType: data.wmsAppCode,
          warehouseChannel: data.wmsAppCode,
          uniqueCode: data.uniqueCode,
        }
      })
      this.$emit('change', list)
    },
    clear() {
      this.value = undefined
      this.valueList = []
    },
  },
  watch: {
    code: {
      handler(val) {
        if (Array.isArray(val)) {
          this.valueList = val
        } else {
          this.value = val
        }
      },
      deep: true,
      immediate: true,
    },
    shopList: {
      handler(val, oldVal) {
        // console.log('val, oldVal: ', val, oldVal)
        if (JSON.stringify(val) === JSON.stringify(oldVal)) return
        this.value = undefined
        this.valueList = []
        if (val.length) {
          this.searchWarehouseList()
        }
      },
      immediate: true,
    },
  },
}
</script>
