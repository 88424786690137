<template>
  <div class="order-sn-modal">
    <a-modal
      v-model="visible"
      width="440px"
      :title="isWaybill ? $t('以下快递单未找到') : $t('以下订单未找到')"
      @cancel="close"
      :footer="null"
    >
      <a-button type="primary" @click="copyOrderSns">
        {{ isWaybill ? $t('复制快递单号') : $t('复制订单号') }}
      </a-button>
      <div class="order-sn-list flex">
        <div class="order-sn-text" v-for="(item, index) in list" :key="index">{{ item }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { copy } from '@/common'
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    searchType: String,
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    isWaybill() {
      return this.searchType === 'waybillNo' || this.searchType === 'WAYBILL_NO'
    },
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    copyOrderSns() {
      let str = this.list.join(' ')
      copy(str)
    },
  },
}
</script>
<style scoped lang="scss">
.order-sn-list {
  margin-top: 12px;
  flex-wrap: wrap;
  .order-sn-text {
    line-height: 24px;
    width: 50%;
  }
}
</style>
