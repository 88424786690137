<template>
  <a-date-picker
    style="width: 100%"
    v-bind="propsValue"
    :placeholder="placehold || $t('选择时间')"
    :disabled="disabled"
    :value="value"
    @change="onChange"
  />
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['id', 'initDateTime', 'placehold', 'disabled', 'needhms', 'aftertoday'],
  computed: {
    ...mapState(['lang', 'locale']),
    propsValue() {
      return {
        id: this.id || 'datepicker',
        'show-time': this.needhms,
        locale: this.locale?.Calendar,
        format: this.format,
      }
    },
    format() {
      let format = 'YYYY-MM-DD HH:mm:ss'
      if (!this.needhms) {
        format = 'YYYY-MM-DD'
      }
      return format
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.value = this.initDateTime != undefined ? this.initDateTime : null
    })
  },
  watch: {
    initDateTime(newVal) {
      this.value = newVal
    },
  },
  data() {
    return {
      value: '',
    }
  },
  methods: {
    onChange(m_value, value) {
      this.value = m_value
      this.$emit('choose', {
        componentId: this.id,
        data: value,
      })
    },
  },
}
</script>
